<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <template>
      <v-card class="elevation-12">
          <v-card-title>Student Bypass Login <v-divider class="mx-4" inset vertical></v-divider> </v-card-title>
          <v-spacer />
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12" sm="3">
                <v-text-field label="Enter PRN / Name / Email / Mobile Number" outlined clearable required
                  v-model="user.prnnoall"></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <center><v-btn class="mt-2 ml-10 mb-2 logi" color="indigo darken-4" dark :loading="loading" @click="getstudent">Get Student</v-btn>
                </center>
              </v-col>
              <v-col v-if="learner_list.length != 0" cols="12" sm="3">
                <v-select class="ml-10" style="width: 500px;" label="Please Select Student For Bypass Login" :items="learner_list" v-model="user.prnno" outlined item-text="stud"></v-select>
              </v-col>
              <v-col v-if="learner_list.length != 0" cols="12" sm="3">
                <v-btn class="ma-5 mt-1 ml-10 mb-2 logi" color="indigo darken-4" dark @click="signIn">Login</v-btn>
              </v-col>
            </v-row>
            <v-row>

            </v-row>
          </v-form>
        </v-card-text>

      </v-card>
    </template>
  </div>
</template>
<script>
import axios from "axios";
import crypto from '@/service/crypto'
export default {
  data() {
    return {
      user: {
        prnno: "",
        prnnoall: "",
      },
      data: [],
      learner_list: [],
      value: String,
      message: "",
      snackbar_msg: "",
      color: "",
      snackbar: false,
      loading: false,
      tokenid: "",
      uidid: "",
      tenantid: "",
      usertypeid: "",
    };
  },
  methods: {

    getstudent() {
      if (this.user.prnnoall.length == 0) {
        this.showSnackbar("red", "Please Enter Prn / Name / Email / Mobile Number");
      } else {
        this.data = [];
        this.loading = true;
        var params = { grlist: this.user.prnnoall };
        axios
          .post("/login/getstudentslist", params)
          .then((res) => {
            this.loading = false;
            if (res.data.msg == "200") {
              this.learner_list = res.data.learner_list;
            } else if (res.data.msg == "402") {
              this.showSnackbar("red", res.data.error);
            } else if (res.data.msg == "NOTFOUNḌ") {
              this.showSnackbar("red", "Learner Not Found");
            }
          });
      }
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },

    signIn() {

      var arg = {
        prnno: this.user.prnno
      }
      var params1 = {
        data: crypto.encrypt(arg)
      }

      axios.post("/admin/bypassAction", params1).then((res) => {

        if (res.data.msg == "200") {
          var params = {
            data: res.data.data
          };
          axios.post("/login/processbypass", params).then((res1) => {

            if (res1.data.msg == "200") {
              this.tokenid = localStorage.getItem("EPS-token");
              this.uidid = localStorage.getItem("EPS-uid");
              this.tenantid = localStorage.getItem("EPS-tenant");
              this.usertypeid = localStorage.getItem("EPS-usertype");
              localStorage.setItem("EPS-tokenbackup", this.tokenid)
              localStorage.setItem("EPS-uidbackup", this.uidid)
              localStorage.setItem("EPS-tenantbackup", this.tenantid)
              localStorage.setItem("EPS-usertypebackup", this.usertypeid)

              localStorage.setItem("awspath", res1.data.awspath);
              localStorage.setItem("EPS-token", res1.data.token);
              localStorage.setItem("EPS-uid", res1.data.uid);
              localStorage.setItem("Isbypass", true);
              localStorage.setItem("instuid", res.data);
              if (res1.data.policy_read == true) this.$router.push("/home");
              else {
                this.dialog = true;
              }
              location.reload();
            }
            else {
              this.showSnackbar("red", res.data.msg);
              return;
            }

          });
        }
        else {
          this.showSnackbar("red", res.data.msg);
          return;
        }



      });
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.tc-title {
  background-image: linear-gradient(-90deg, skyblue, #057996);
  color: #fff;
  border-radius: 3px;
}
</style>